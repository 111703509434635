<template>
  <v-container fluid class="mt-16 mb-4 pt-4">
    <v-card class="bgImg elevation-15">
      <v-row class="ma-1 ma-lg-12 mt-10 mt-md-0">
        <v-spacer />

        <v-col cols="12" xs="5" md="4" xl="5">
          <v-img
            max-width="22.5vh"
            max-height="22.5vh"
            class="mx-auto offset-img rounded-xl neu-shadow cursor-pointer"
            cover
            :src="productData.pictures[0]"
            @click="$router.push('/product')"
          />
          <v-card class="mt-n14 pt-14 glasscard-primary elevation-2" color="sand">
            <v-card-text class="text-center">
              <v-col>
                <v-skeleton-loader v-if="!productData.name" type="heading" color="transparent" />
                <h1 v-else class="monkey-title mb-4">{{ productData.name }}</h1>

                <v-skeleton-loader v-if="!productData.shortDesc" type="paragraph" color="transparent" />
                <p v-else class="" v-html="productData.shortDesc" />

                <v-btn
                  color="primary"
                  class="mt-4 px-6 mx-10"
                  to="/product"
                >En savoir plus</v-btn>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>

  <v-carousel
    v-if="smAndDown"
    class="bg-accent text-center"
    height="180px"
    hide-delimiter-background
    show-arrows="hover"
    cycle
    delimiter-icon="far fa-circle"
  >
    <v-carousel-item
      v-for="feature in features"
      :key="feature"
      class="pt-7"
    >
      <v-icon
        :icon="feature.icon"
        color="sand"
        class="mb-2"
      />

      <h3 class="monkey-title">{{ feature.title.toUpperCase() }}</h3>
      <h4 class="font-weight-regular">{{ feature.subtitle }}</h4>
    </v-carousel-item>
  </v-carousel>
  <v-row v-else class="bg-accent text-center pa-2 pa-sm-10">
    <v-col
      v-for="feature in features"
      :key="feature"

      cols="12"
      sm=""
    >
      <v-icon
        :icon="feature.icon"
        color="sand"
        class="mb-2"
      />

      <h3 class="monkey-title">{{ feature.title.toUpperCase() }}</h3>
      <h4 class="font-weight-regular">{{ feature.subtitle }}</h4>
    </v-col>
  </v-row>

  <v-container fluid class="mb-13 mt-3">
    <v-row>
      <v-col cols="12">
        <h1 class="text-h4 monkey-title text-accent text-center mt-sm-3 mb-0">La parole à nos clients</h1>
      </v-col>

      <v-container fluid>
        <v-row dense>

          <v-col
            v-for="review in reviews"
            :key="review"
            cols="12"
            sm="6"
            md="3"
          >
            <v-card class="neu-card" height="200px">
              <v-card-text>
                <v-row class="px-2">
                  <v-col cols="auto" class="text-center">
                    <v-avatar color="surface-variant">{{ review.name[0].toUpperCase() }}</v-avatar>
                    <p class="text-caption mt-1">{{ review.name }}</p>
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-rating
                      v-model="review.rating"
                      active-color="primary"
                      density="comfortable"
                      readonly
                      half-increments
                    />
                  </v-col>
                </v-row>


                <div class="text-center pt-4">
                  <p class="text-body-1">
                    <v-icon
                      class="offset-quote text-blue-grey-lighten-4"
                      size="x-large"
                    >fa fa-quote-left</v-icon>
                    {{ review.review }}
                    <v-icon
                      class="offset-end-quote text-blue-grey-lighten-4"
                      size="x-large"
                    >fa fa-quote-right</v-icon>
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </v-row>
  </v-container>
</template>

<script setup>
import { useDisplay } from "vuetify";

const { smAndDown } = useDisplay();
</script>

<script>
import axios from "axios";
import { useStoreStore } from "~/stores/store";

export default {
  name: "HomePage",
  data() {
    return {
      productData: {
        pictures: [0],
      },
      errorProductLoading: false,

      reviews: [
        {
          name: "Mylène",
          rating: 3.5,
          review: "Ce sont des rachats. J’aime ces produits",
        },
        {
          name: "Florette G.",
          rating: 1.1,
          review: "Après avoir testé de nombreuse crème sans résultat, celle-ci fonctionne vraiment !",
        },
        {
          name: "jessica h.",
          rating: 5,
          review: "Pratique, compacte et cette folle odeur !",
        },
        {
          name: "S.R.",
          rating: 0.3,
          review: "이 작은 러브 크림이 정말 사랑스러워요.",
        },
      ],

      features: [
        {
          title: "Livraison 24h offerte",
          subtitle: "Dès 60€ d'achat en France",
          icon: "fa fa-truck",
        },
        {
          title: "Made in France",
          subtitle: "Gage de qualité et de traçabilité",
          icon: "fa fa-wine-glass",
        },
        {
          title: "Paiement sécurisé",
          subtitle: "Visa, Mastercard, Paypal",
          icon: "fa fa-shield-heart",
        },
        {
          title: "Efficacité prouvée",
          subtitle: "Par un laboratoire indépendant",
          icon: "fa fa-vial-circle-check",
        },
      ],
    };
  },
  created() {
    const config = useRuntimeConfig();
    const storeStore = useStoreStore();

    const productid = "X9ZUtmksaPsMOAICtzY7";

    if (storeStore.products[0]) this.productData = storeStore.products[0];

    axios.get(`${config.public.apiBase}/getProduct?productId=${productid}`, {
      headers: {
        Authorization: `Bearer ${config.public.apiToken}`,
      },
    }).then((response) => {
      this.productData = response.data.product;

      storeStore.updateProducts([this.productData]);
    }).catch(() => {
      this.errorProductLoading = true;
    });
  },
};
</script>

<style scoped>
.bgImg {
  height: 80vh;

  background-image: url("@/assets/illus/kaeme-unsplash.jpg")!important;
  background-position: center!important;
  background-size: cover!important;
}
.offset-img {
  margin-top: 10px;
  z-index: 10!important;
}

.offset-quote {
  position: relative;
  top: -10px;
  left: 12px;
  z-index: -1;
  font-size: 2rem;
}

.offset-end-quote {
  position: relative;
  top: 10px;
  right: 12px;
  z-index: -1;
  font-size: 2rem;
}
</style>
